import React, { Component } from 'react'
import poo from "../src/img/pp.jpg"
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import Appheader from '../src/Appheader'
import AdjustIcon from '@mui/icons-material/Adjust';

import imgf from "../src/img/imgf.jpg"
import map2 from "../src/img/ggg.png"
import snp from "../src/img/snp.webp"

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    career
    </Typography>,
  ];

export class About extends Component {
  render() {
    return (
      <div>
       <Appheader/>
      <Box sx={{backgroundImage:{xs:`url(${poo})`,sm:`url(${poo})`},width:'100%',display:'flex',height:{xs:200,sm:250,md:310},backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
      <br/>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box sx={{background:'rgba(28,68,106,.7)',height:{xs:140,sm:180,md:200}, width:'100%',mt:1,}}>
      <Box sx={{mt:2}}>
      <br/>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>ABOUT THE INCC</Typography>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>TECH</Typography>
      </Box>


      <Box sx={{display:'flex',justifyContent:'center',mt:{xs:2,sm:2,md:5}}}>
      <Box sx={{backgroundColor:'#c7167a',height:40,width:150,borderRadius:10,}}>
<Box sx={{display:'flex',justifyContent:'center'}} >
<Box>
<Typography sx={{mt:1,color:'white'}}>Learn</Typography>
</Box>
</Box>
      </Box>
      </Box>
      </Box>
      </Container>
      </Box> 
 </Box>




 <Box sx={{minHeight:300,backgroundColor:'#e8eeee ',opacity:0.9,backgroundRepeat:''}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
 <Box sx={{mt:1,mb:1,minHeight:300,}}>
 
 <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',flexDirection:'column'}}>
  <br/>
  <Typography variant='h1'  sx={{fontSize:{xs:25,sm:25,md:34},fontWeight:'800',fontFamily:'sans-serif'}}>We didn't find it for us,</Typography>
  <Typography variant='h1'  sx={{fontSize:{xs:25,sm:25,md:34},fontWeight:'800',mt:1,fontFamily:'sans-serif',color:'#c7167a '}}>so we created it for you</Typography>
  <br/>
  <Typography align='justify' sx={{mb:2,mr:{xs:'0',sm:'0',md:'10%',},fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:13,sm:13,md:17,lg:17},lineHeight:2}}>That's essentially our story in one sentence.
  A treasure trove of knowledge and inspiration.
Expert professors, captivating lectures.
Lifetime access to a vast library of learning.
Unlocking potential, igniting curiosity.
Empowering minds, one course at a time.
Transforming lives through education.</Typography>
  </Box>
  
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>

  <Box sx={{m:{xs:'0',sm:'0',md:'2'}}}>
  <Box sx={{borderRadius:10,height:370,}}>
  <Box
     component="img"
     sx={{
       height:370,
       display: 'block',
     objectFit:'cover',
       overflow: 'hidden',
       width:'100%',
       borderRadius:10
     }}
     src={imgf}
     alt=''
   />
  </Box>
  </Box>



    
  </Grid>
</Grid>
 </Box>
 <br/>
 </Container>
</Box>
 </Box>








 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
   <Box sx={{minHeight:250,}}>

   <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Your gateway to lifelong learning.

Explore a vast array of courses, tailored to your needs.

From coding to cooking, business to ballet, find your passion.

Learn from industry experts, renowned professors, and creative minds. !</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Flexible learning, at your own pace, on your own time.

High-quality video lectures, interactive quizzes, and practical exercises.

Engage with a vibrant community of learners and share insights.

Earn certificates and credentials to advance your career.. </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Unlock your potential and achieve your goals.

Empowering individuals to reach new heights.

Invest in yourself, invest in your future.

Join a global movement of lifelong learners..</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Transform your life through education.

Your Learning, Your Way

Personalized learning paths to suit your unique style.

Adaptive learning algorithms to optimize your progress.</Typography>
</Box>
</Box>



   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{minHeight:250,}}>

  <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mobile-friendly access to learn anytime, anywhere.

Seamless integration with your favorite devices.

Intuitive interface for a smooth learning experience.

Dedicated support team to assist you every step of the way.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Secure and reliable platform to protect your privacy.

Transparent pricing and flexible payment options.

A commitment to quality and innovation.

Your success is our priority.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Enroll in a course and embark on a transformative journey.

Join a global community of learners and connect with like-minded individuals.

Experience the power of lifelong learning.

Your future starts here.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Invest in your future, today.</Typography>
</Box>
</Box>


  </Box>
  </Grid>
 </Grid>
 
 </Container>
 </Box>
 <br/>
  </Box>






 










      
      </div>
    )
  }
}

export default About








