import { Box, Container } from '@mui/material'
import React, { Component } from 'react'
import { styled } from '@mui/system';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';




export class Subheadertags extends Component {
  render() {
    return (
      <div>
        <Box sx={{height:35,width:'100%',backgroundColor:'#282a36',display:'flex',alignItems:'center'}}>
        <Container  maxWidth={false}>
<Box sx={{display:'flex',flexDirection:'row'}}>

<IconButton edge="start" color="inherit" aria-label="menu" sx={{marginRight:{xs:1,sm:0,md:0,lg:0},display:{xs:'none',sm:'none'}  }} >
<MenuIcon sx={{color:'#fff',height:'50',width:'50'}}/> 
</IconButton>

        <Tabs
              variant='scrollable'
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  display:'none'
                }
              }}
              value='one'
              onChange={this.handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label="HTML"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                wrapped
              />
              <Tab
                value="two"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="CSS"
              />
              <Tab
                value="three"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="PYTHON"
              />
              <Tab
                value="four"
                onClick={() =>window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() =>window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() =>window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
                <Tab
                value="four"
                onClick={() => window.open('/main')}
                sx={{ fontSize: 11, fontWeight: "bold", color: "#fff" }}
                label="JAVA SCRIPT"
              />
            </Tabs>


</Box>
            </Container>
        </Box>
      </div>
    )
  }
}

export default Subheadertags