import React, { Component } from 'react'
import Box from '@mui/material/Box';
import computer from "../src/img/mobileee.png"
import cc from "../src/img/computer.png"
import doctor from "../src/img/doctor.png"
import CircleIcon from '@mui/icons-material/Circle';
import { Container, Toolbar,Grid, Card,Typography,Divider, AppBar } from '@mui/material';
import Appheader from  './Appheader'
export class Covid extends Component {
  render() {
    return (
      <div>
       <Appheader/>
      <Box sx={{backgroundColor:'#fff',width:'100%',mt:1,display:{xs:'none',sm:'none',md:'block'}}}>
      <img
      src={computer}
      alt="Second slide"
      style={{objectFit:'cover',width:'100%'}}
    />
      </Box>
      
      <Box sx={{backgroundColor:'#fff',width:'100%',mt:1,display:{xs:'block',sm:'block',md:'none'}}}>
      <img
      src={cc}
      alt="Second slide"
      style={{objectFit:'cover',width:'100%'}}
    />
      </Box>

     
<Box sx={{backgroundColor:'#f1f4f6',minHeight:300}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
      <br/>
<Box>
<Typography align='justify'  sx={{mb:2,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>The covid-19 pandemic has brought health and hygiene to the forefront. As one of India’s most trusted secure place to work, we have adopted covid safety protocols in all our premises to ensure the health and safety of our employee.</Typography>
 <Divider/>
 <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#f8bc0d",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Daily deep cleaning and sanitization of rooms and common areas.</Typography>
    </Box>
   

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#f8bc0d",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Health insurance of 1 lakh INR for all Employees.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#f8bc0d",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Fully vaccinated staff and employees.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#f8bc0d",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Isolation and covid quarantine protocols in place in cases of covid-19 breakouts.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#f8bc0d",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Compulsory wearing of masks and social distancing in common areas.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#f8bc0d",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>INCC TECH Living tie-up with a home health care service provider for covid testing as and when required.</Typography>
    </Box>

<br/>
</Box>
</Container>
</Box>
</Box>


      
      </div>
    )
  }
}

export default Covid