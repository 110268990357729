import React, { Component } from 'react'
import Appheader from './Appheader'

export class Refund_policy extends Component {
  render() {
    return (
      <div>
        <Appheader/>
        Refund_policy</div>
    )
  }
}

export default Refund_policy