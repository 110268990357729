import React, { Component } from "react";
import { Box, Container, Divider, Paper, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import poo from '../src/img/bg.jpg'
import { Button, TextField,Card, ButtonGroup } from '@mui/material'
import Grid from '@mui/material/Grid2';
import agreeculture from "../src/imgfolder/agreeculture.png"

import lr from '../src/img/lt.webp'

import law from "../src/imgfolder/law.png"


import management from "../src/imgfolder/management.png"
import medical from "../src/imgfolder/medical.png"

import paramedical from "../src/imgfolder/paramedical.png"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { locale } from 'moment';
import Appheader from "./Appheader";




export class Landingpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
     


    };
  }



  componentDidMount() {

  }




  retriveData = () => {
 

  };

  componentWillUnmount() {
   
  }

  render() {
    return (
      <Box>
<Appheader/>


<Box sx={{backgroundImage:`url(${poo})`,width:'100%',backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat',}}>
      <Box sx={{backgroundColor:'rgba(28,68,106,0.9)',height:{xs:350,sm:350,md:450},width:'100%'}}>
<br/>
<br/>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center', mt:{xs:5,sm:5,md:8}}}>
<Typography align='center' sx={{fontSize:24,fontWeight:'bold',color:'white'}}>Welcome to </Typography>
<Typography sx={{fontSize:24,fontWeight:'bold',color:'red',ml:0.6}}> Inccweb.in</Typography>
</Box>
<Typography align='center' sx={{fontSize:18,fontWeight:'bold',mt:2,color:'white',ml:1,mr:1}}>Explore Top Courses, Best Examination & Scholarships</Typography>
  <Box sx={{backgroundColor:'white',ml:{xs:'8%',sm:'8%',md:'30%'},mr:{xs:'8%',sm:'8%',md:'30%'},height:50,mt:{xs:4,sm:4,md:6},borderRadius:8}}>
  <Grid container>
  <Grid size={{xs:10,sm:10,md:10}}>
<Box sx={{backgroundColor:'white',height:50,borderRadius:8}}>
<Box sx={{display:'flex',justifyContent:'left'}}>
<Box sx={{mt:1,ml:1}}>
<TextField
size='medium'
fullWidth
name='search'
onChange={this.handlechange}
sx={{ml:3,width:'100%'}}
variant='standard'
placeholder='Search Our Best Course'
InputProps={{
  disableUnderline:true,
}}
/>

</Box>
</Box>
</Box>
  </Grid>
  <Grid size={{xs:2,sm:2,md:2}}>
  <Box sx={{height:50,display:'flex',justifyContent:'center',alignItems:'center',width:'100%',backgroundColor:'#4d95dc',borderTopRightRadius:30,borderBottomRightRadius:30}}>

<Box sx={{height:40,width:'100%',backgroundColor:'#4d95dc',borderRadius:4,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
<SearchIcon sx={{height:25,width:25,color:'#fff'}} />
</Box>

</Box>
  </Grid>
  </Grid>
  </Box>    

      
      </Box>
</Box>
      




<Box sx={{backgroundColor:'#f9f9f9'}}>
  <br/>
<Container maxWidth='xl'>
<Box>
  <Typography sx={{textAlign:'center',fontSize:22,fontWeight:'bold',mt:1,mb:2,color:'#4d95dc'}}>Top courses</Typography>
<Grid container spacing={0}>
{
arr.map((r)=>(
    <Grid item size={{xs:4,sm:4,md:3,lg:3}}>
   <Box sx={{height:130,display:'flex',justifyContent:'center', '&:hover': {backgroundColor: 'white',},}} >
   <Box sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
   <img
src={r.img}
style={{height:45,marginTop:5}}
   /> 
<Typography  sx={{color:'#00337b',mt:1,fontSize:17,textDecoration:' none',fontFamily:'serif',fontWeight:520,opacity:0.9}}>{r.value}</Typography>
<Typography  sx={{color:'#00337b',mt:0.3,fontSize:13,textDecoration:' none',fontFamily:'serif',fontWeight:520,opacity:0.9}}>Courses {r.num}</Typography>

</Box>
</Box>
  </Grid>
))
}


<Box sx={{display:'flex',justifyContent:'center',width:'100%',mt:3}}>
<Button disableElevation size='small' variant="contained" sx={{textTransform:'none',}}>Explore More</Button>
</Box>



</Grid>
</Box>
</Container>
<br/>
</Box>





<Box sx={{minHeight:350}}>
  <br/>
  <Typography  sx={{fontSize:18,fontWeight:'600',color:'blue',textAlign:'center',mb:2}}>INCC COURSE COMPANY</Typography>
  <Container maxWidth='xl'>
<Grid container spacing={2} >
    <Grid item size={{xs:12,sm:4}}>
<Paper elevation={1} sx={{minHeight:380,width:'100%',backgroundColor:'#230d6f',borderRadius:0}}>
  <Box sx={{height:60,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
  <img
src={law}
style={{height:45,marginTop:15}}
   />   
  </Box>

<Typography sx={{fontSize:20,fontWeight:'bold',color:'#fff',textAlign:'center',padding:1}}>Exam score & result</Typography>
<Typography sx={{fontSize:14,fontWeight:'500',color:'#fff',textAlign:'justify',paddingLeft:3,paddingRight:3,mt:1}}>Campus On Click's result generation module provides result management with the flexibility to generate results in various ways. Whether it's class-wise, subject-wise, examination-wise, term-wise, or annual results, the platform allows administrators to adapt the system to their desired parameters.</Typography>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',mt:5}}>

<Box sx={{height:50,width:130,backgroundColor:'#fff',borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center'}}>
<Typography sx={{fontSize:14,fontWeight:'bold',color:'black'}}>READ MORE</Typography>
</Box>

</Box>



</Paper>
    </Grid>

    <Grid item size={{sx:12,sm:4}}>
    <Paper elevation={1} sx={{minHeight:380,width:'100%',backgroundColor:'#fff',borderRadius:0}}>
  <Box sx={{height:60,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
  <img
src={law}
style={{height:45,marginTop:15}}
   />   
  </Box>

<Typography sx={{fontSize:20,fontWeight:'bold',color:'black',textAlign:'center',padding:1}}>Exam Preparation</Typography>
<Typography sx={{fontSize:14,fontWeight:'500',color:'black',textAlign:'justify',paddingLeft:3,paddingRight:3,mt:1}}>Based on current information, INCCtech is widely considered one of the best exam preparation service providers, particularly for competitive government exams in India, offering a comprehensive range of study materials, mock tests, and personalized learning features across various exam categories like banking, railways, and SSC</Typography>

<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',mt:5}}>
<Box sx={{height:50,width:130,backgroundColor:'#230d6f',borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center'}}>
<Typography sx={{fontSize:14,fontWeight:'bold',color:'#fff'}}>READ MORE</Typography>
</Box>

</Box>
</Paper>
  </Grid>


  <Grid item size={{sx:12,sm:4}}>
  <Paper elevation={1} sx={{minHeight:380,width:'100%',backgroundColor:'#230d6f',borderRadius:0}}>
  <Box sx={{height:60,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
  <img
src={law}
style={{height:45,marginTop:15}}
   />   
  </Box>

<Typography sx={{fontSize:20,fontWeight:'bold',color:'#fff',textAlign:'center',padding:1}}>Mock test</Typography>
<Typography sx={{fontSize:14,fontWeight:'500',color:'#fff',textAlign:'justify',paddingLeft:3,paddingRight:3,mt:1}}>According to available information, some of the best platforms for taking mock tests include Testbook for its comprehensive and cost-effective test series, iQuanta for AI-based CAT mock tests with detailed analysis, Kaplan for GMAT practice tests with realistic question patterns, and Khan Academy for free SAT practice tests with detailed explanations.</Typography>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',mt:5}}>

<Box sx={{height:50,width:130,backgroundColor:'#fff',borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center'}}>
<Typography sx={{fontSize:14,fontWeight:'bold',color:'black'}}>READ MORE</Typography>
</Box>

</Box>



</Paper>
    </Grid>
    </Grid>
    </Container>
    <br/>
</Box>





<Box sx={{minHeight:350,backgroundColor:'#f0f8fb'}}>
  <br/>
  <Typography  sx={{fontSize:18,fontWeight:'600',color:'blue',textAlign:'center',mb:2}}>---ABOUT US---</Typography>
  <Container maxWidth='xl'>
<Grid container spacing={2} >
    <Grid item size={{xs:12,sm:5}}>
<Box sx={{height:400}}>

<img
src={lr}
style={{height:400,width:'100%',objectFit:'fill'}}
   /> 

</Box>
    </Grid>

    <Grid item size={{sx:12,sm:7}}>
    <Box sx={{minHeight:400,mt:{xs:0,sm:3}}}>
<br/>
     <Typography sx={{fontSize:28,fontWeight:'bold',color:'black',mb:2}}>We strike with solutions that have an effect. </Typography>
     <Typography sx={{fontSize:15,fontWeight:'500',color:'black',textAlign:'justify',fontFamily:'sans-serif'}}>INCC course provider is an organization or individual that offers educational courses or training programs. They may specialize in specific subjects, industries, or skill sets. Course providers can deliver their programs in various formats, including in-person classroom training, online learning, or a hybrid approach..</Typography>


      <Box sx={{display:'flex',flexDirection:'row',mt:2}}>
        <Box sx={{height:60,width:150,backgroundColor:'#4d95dc',display:'flex',justifyContent:'center',alignItems:'center'}}>
         <Typography sx={{fontWeight:'bold',color:'#fff'}}>Our mission</Typography>
        </Box>

        <Box sx={{height:60,width:150,backgroundColor:'#00337b',ml:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Typography sx={{fontWeight:'bold',color:'#fff'}}>Our Vision</Typography>
        </Box>

      </Box>


      <Typography sx={{fontSize:15,fontWeight:'500',color:'black',textAlign:'justify',mt:2,fontFamily:'sans-serif'}}>They often provide certification or accreditation upon course completion, validating learners' skills and knowledge. Course providers play a crucial role in professional development, upskilling, and lifelong learning by offering opportunities to acquire new skills and advance careers.</Typography>
   </Box>
  </Grid>

    </Grid>
    </Container>
    <br/>
</Box>
































      </Box>
    );
  }
}

export default Landingpage;




const arr = [
  {id:0,
     couse:'HTML'
     ,value:'Javascript',
     num:"200",
  img:agreeculture
  }
  ,{id:1,couse:'HTML',value:'Javascript',img:agreeculture, num:"254",},
  ,{id:4,couse:'ITCOURSE',value:"Javascript",img:agreeculture, num:"104",},
  

  
  ,{id:10,couse:'HTML',value:'Javascript',img:agreeculture, num:"447",},


  {id:14,couse:'HTML',value:'Javascript',img:agreeculture, num:"164",},
 
  ,{id:16,couse:'HTML',value:'Javascript',img:"law",img:agreeculture, num:"145",},
  {id:17,couse:'HTML',value:'Javascript',img:agreeculture, num:"134",},

  {id:20,couse:'HTML',value:'Javascript',img:agreeculture, num:"147",},
 


]
































