import React, { Component } from "react";
import poo from "../src/img/pp.jpg";
import mm from "../src/img/18939.jpg";
import { Paper, Box, Link } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
  Container,
  Typography,
  Card,
  Grid,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import base_url from "./base_url";

import map2 from "../src/img/map2.png";
import Appheader from "./Appheader";
const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    href="/"
    sx={{ fontSize: 14, color: "#c7167a" }}
  >
    Home
  </Link>,

  <Typography
    key="3"
    color="text.primary"
    sx={{ fontSize: 14, color: "#c7167a" }}
  >
    career
  </Typography>,
];



export class Contactus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Perpus: "",
      name: "",
      emailid: "",
      mobileno: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };




  addContact = () => {
    fetch(`${base_url.base_url}/addContact`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body:JSON.stringify({
        email : this.state.emailid,
        name : this.state.name,
        mob_no : this.state.mobileno,
        desc : this.state.Perpus,
      })
    }).then((res) => {
        return res.json();
      })
      .then((result) => {
    
        this.setState({
          Perpus: "",
          name: "",
          emailid: "",
          mobileno: "",
        },()=>{
          alert('Your request send to our team, we shortly contact to you!')
        })
      });
  };

  render() {
    return (
      <div>
            <Appheader/>
        <Box
          sx={{
            backgroundImage: `url(${map2})`,
            minHeight: 300,
            backgroundColor: "#022246 ",
            opacity: 0.9,
            mt: 2,
          }}
        >
          <Box
            sx={{
              paddingLeft: { xs: 0, sm: 3 },
              paddingRight: { xs: 0, sm: 3 },
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box sx={{ mt: { xs: 1, sm: 1, md: 10} }}>
                    <Box>
                      <Typography
                        align="justify"
                        sx={{
                          mb: 2,
                          fontWeight: "335",
                          fontFamily: "Roboto Slab",
                          color: "#fff",
                          fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                        }}
                      >
                        Come build the future with us!
                      </Typography>
                      <Typography
                        align="justify"
                        sx={{
                          mb: 2,
                          fontWeight: "335",
                          fontFamily: "Roboto Slab",
                          color: "#fff",
                          fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                        }}
                      >
                      A treasure trove of knowledge and inspiration.
Expert professors, captivating lectures.
Lifetime access to a vast library of learning.
Unlocking potential, igniting curiosity.
Empowering minds, one course at a time.
Transforming lives through education.
A digital marketplace for lifelong learning.
Connecting learners with expert instructors.
Diverse courses, flexible learning paths.
Empowering individuals to reach their full potential.
A community of knowledge-seekers and creators.
Your gateway to a world of endless possibilities.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      height: 400,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: { xs: "0", sm: "0", md: "20%" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: { xs: "0", sm: "0", md: "15%" },
                        marginRight: { xs: "0", sm: "0", md: "15%" },
                      }}
                    >
                      <br />
                      <Typography
                        align="center"
                        sx={{ m: 2, fontWeight: "bold", color: "#004285" }}
                      >
                        CONTACT FORM
                      </Typography>
                      <TextField
                        InputProps={{
                          style: { fontSize: 14, fontWeight: "bold" },
                        }}
                        onChange={this.handleChange}
                        value={this.state.Perpus}
                        id="outlined-basic"
                        label="For what purpose you are filling the form"
                        name="Perpus"
                        variant="outlined"
                        size="small"
                        sx={{ m: 1 }}
                      />
                      <TextField
                        InputProps={{
                          style: { fontSize: 14, fontWeight: "bold" },
                        }}
                        onChange={this.handleChange}
                        value={this.state.name}
                        id="outlined-basic"
                        label="Enter name"
                        name="name"
                        variant="outlined"
                        size="small"
                        sx={{ m: 1, fontSize: 10 }}
                      />
                      <TextField
                        InputProps={{
                          style: { fontSize: 14, fontWeight: "bold" },
                        }}
                        value={this.state.emailid}
                        onChange={this.handleChange}
                        id="outlined-basic"
                        label="Enter email id"
                        name="emailid"
                        variant="outlined"
                        size="small"
                        sx={{ m: 1 }}
                      />

                      <TextField
                        InputProps={{
                          style: { fontSize: 14, fontWeight: "bold" },
                        }}
                        value={this.state.mobileno}
                        onChange={this.handleChange}
                        id="outlined-basic"
                        label="Enter contact no "
                        name="mobileno"
                        variant="outlined"
                        size="small"
                        sx={{ m: 1 }}
                      />
                      <br />

                      {/*
this.state.circle?<Box sx={{display:'flex',justifyContent:'center'}}><CircularProgress size={24} /> </Box>:null
    */}

                      <br />
                      <Button
                        variant="contained"
                        sx={{ width: 200, backgroundColor: "#f8bc0d" }}
                        onClick={() => {
                          if (
                            this.state.mobileno !== "" &&
                            this.state.emailid !== "" &&
                            this.state.name !== "" && this.state.Perpus!==""
                          ) {
                            this.addContact();
                          } else {
                            alert("All fields are complessary");
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <br />
        </Box>
      </div>
    );
  }
}

export default Contactus;
