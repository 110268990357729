import React, { Component } from 'react'
import poo from "../src/img/pp.jpg"
import mm from "../src/img/18939.jpg"
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import bb from "../src/img/bb.webp"
import moment from 'moment';
import p1 from "../src/img/p1.webp"
import p2 from "../src/img/p2.webp"
import p3 from "../src/img/p3.webp"
import base_url from './base_url'

import map2 from "../src/img/map2.png"
import Appheader from './Appheader';
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    career
    </Typography>,
  ];

export class Career extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      Perpus  :"",
      name: "",
      emailid : "",
      mobileno:"",
    
      
    }
    this.handleChange=this.handleChange.bind(this);
  
  }

    
    
    handleChange=(e)=>{
    this.setState({[e.target.name]:e.target.value})
    }
    


    
  addContact = () => {
    fetch(`${base_url.base_url}/addContact`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body:JSON.stringify({
        email : this.state.emailid,
        name : this.state.name,
        mob_no : this.state.mobileno,
        desc : this.state.Perpus,
      })
    }).then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          Perpus: "",
          name: "",
          emailid: "",
          mobileno: "",
        },()=>{
          alert('Your request send to our team, we shortly contact to you!')
        })
      });
  };

  render() {
    return (
      <div>
    <Appheader/>
      <Box sx={{backgroundImage:{xs:`url(${poo})`,sm:`url(${poo})`},width:'100%',display:'flex',height:{xs:200,sm:250,md:310},backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
      <br/>
      <br/>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box sx={{background:'rgba(28,68,106,.7)',height:{xs:140,sm:180,md:200}, width:'100%',mt:1,}}>
      <Box sx={{mt:2}}>
      <br/>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>JOIN THE INCC</Typography>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>TECH</Typography>
      </Box>


      <Box sx={{display:'flex',justifyContent:'center',mt:{xs:2,sm:2,md:5}}}>
      <Box sx={{backgroundColor:'#c7167a',height:40,width:150,borderRadius:10,}}>
<Box sx={{display:'flex',justifyContent:'center'}} >
<Box>
<Typography sx={{mt:1,color:'white'}}>JOIN</Typography>
</Box>
</Box>
      </Box>
      </Box>
      </Box>
      </Container>
      </Box> 
 </Box>




 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
<Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
 <Container maxWidth='xl'>
 <br/>
 <Grid container spacing={2}>
 <Grid item xs={12} sm={12} md={5} lg={5}>
  <Box sx={{mt:{xs:1,sm:1,md:10}}}>
  <Box>
  <Typography align='justify'  sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>
  Come build the future with us!
 </Typography>
  <Typography align='justify'  sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>
  We are a rapidly growing tech provider and we are always looking for ways to innovate our services and make INCC grow sustainably. If our mission and growth-oriented mindset resonate with you, we can’t wait to discuss this further, over coffee.
 </Typography>
  </Box>
  </Box>
 </Grid>
 <Grid item xs={12} sm={12} md={7} lg={7}>
 <Box sx={{backgroundColor:'white',height:400,display:'flex',flexDirection:'column',marginLeft:{xs:'0',sm:'0',md:'20%'}}}>
 <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:'0',sm:'0',md:'15%',},marginRight:{xs:'0',sm:'0',md:'15%',}}}>
 <br/>
 <Typography align='center' sx={{m:2,fontWeight:'bold',color:'#004285'}}>CONTACT FORM</Typography>
 <TextField value={this.state.Perpus}  onChange={this.handleChange} id="outlined-basic" label="For what purpose you are filling the form" name='Perpus' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
<TextField value={this.state.name} onChange={this.handleChange} id="outlined-basic" label="Enter name" name='name' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
 <TextField value={this.state.emailid} onChange={this.handleChange} id="outlined-basic" label="Enter email id" name='emailid' variant="outlined"  size='small' sx={{m:1}}/>
 < TextField value={this.state.mobileno} onChange={this.handleChange} id="outlined-basic" label="Enter contact no " name='mobileno' variant="outlined"  size='small' sx={{m:1}}/>
<br/>

{/*
this.state.circle?<Box sx={{display:'flex',justifyContent:'center'}}><CircularProgress size={24} /> </Box>:null
    */}

<br/>
 <Button variant="contained" sx={{width:200,backgroundColor:'#f8bc0d'}} onClick={()=>{

if(this.state.mobileno!==""&&this.state.emailid!==""&&this.state.name!=="" && this.state.Perpus!=="")

{

this.addContact()

}else{
alert("All fields are complessary")

}




 }}>Submit</Button>


 
 </Box> 
   



   </Box>
 </Grid>
</Grid>

</Container>
</Box>
<br/>
 </Box>
 
 






<Box sx={{backgroundColor:'#e1e9e9',minHeight:450}}>
<Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3}}>
 <Container maxWidth='xl'>
 <Box>
 <Grid container spacing={8}>
  <Grid item xs={12} sm={12} md={4} lg={4}>
    <Box>
    <Typography align='left' sx={{fontSize:26}}>WORKING AT</Typography>
    <Typography align='left' sx={{fontSize:29,fontWeight:'bold',mb:2}}>INCC TECH</Typography>
    <Typography align='justify' sx={{lineHeight:2,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:13,sm:13,md:15,lg:15}}}>At INCC tech, we believe that satisfying results are only achievable when every team member feels like they belong! Our mission while hiring is to understand the potential of every team member. We are looking for individuals who take ownership, are ready to step out of their comfort zone, and create a jovial atmosphere for all.</Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={8} lg={8}>
    <Box>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 6 }}>
    <Grid item xs={6} sm={6} md={3} lg={3}>
     <Card sx={{borderRadius:30,backgroundColor:'#fff',height:455}}>
     <Box sx={{m:2}}>
     <img
     src={bb}
     alt=''
     style={{width:'100%',objectFit:'contain'}}
     />
     </Box>

     <Box sx={{m:2}}>
     <Typography align='center' variant='h4' sx={{fontSize:19,fontWeight:'650'}}>Growth & Ownership</Typography>
     </Box>

     <Box sx={{m:2}}>
     <Typography align='center' variant='h6' sx={{fontSize:15,fontWeight:'550'}}>Ownership as a value, belief or practice ensures professional and intellectual growth of our employees.</Typography>
     </Box>

     
     </Card>
    </Grid>
    <Grid item xs={6} sm={6} md={3} lg={3}>
    <Card sx={{borderRadius:30,backgroundColor:'#fff',height:455}}>
    <Box sx={{m:2}}>
    <img
    src={p1}
    alt=''
    style={{width:'100%',objectFit:'contain'}}
    />
    </Box>

    <Box sx={{m:2}}>
    <Typography align='center' variant='h4' sx={{fontSize:19,fontWeight:'650'}}>Workplace Equality</Typography>
    </Box>

    <Box sx={{m:2}}>
    <Typography align='center' variant='h6' sx={{fontSize:15,fontWeight:'550'}}>Equality in a workplace is a must. We ensure that no employee of ours feels like an outsider looking on the inside.</Typography>
    </Box>

    
    </Card>
    </Grid>
    <Grid item xs={6} sm={6} md={3} lg={3}>
    <Card sx={{borderRadius:30,backgroundColor:'#fff',height:455}}>
    <Box sx={{m:2}}>
    <img
    src={p2}
    alt=''
    style={{width:'100%',objectFit:'contain'}}
    />
    </Box>

    <Box sx={{m:2}}>
    <Typography align='center' variant='h4' sx={{fontSize:19,fontWeight:'650'}}>Work-Life Balance</Typography>
    </Box>

    <Box sx={{m:2}}>
    <Typography align='center' variant='h6' sx={{fontSize:15,fontWeight:'550'}}>Work hard, play hard- these are the words we live by! A healthy work-life balance is our promise!.</Typography>
    </Box>

    
    </Card>
  </Grid>
  <Grid item xs={6} sm={6} md={3} lg={3}>
  <Card sx={{borderRadius:30,backgroundColor:'#fff',height:455}}>
     <Box sx={{m:2}}>
     <img
     src={p3}
     alt=''
     style={{width:'100%',objectFit:'contain'}}
     />
     </Box>

     <Box sx={{m:2}}>
     <Typography align='center' variant='h4' sx={{fontSize:19,fontWeight:'650'}}>Food Available Always</Typography>
     </Box>
     <Box sx={{m:2}}>
     <Typography align='center' variant='h6' sx={{fontSize:15,fontWeight:'550'}}>We understand that one simply can’t function without food for where there is good food, there is great productivity!.</Typography>
     </Box>
     </Card>
  </Grid>
  </Grid>
    </Box>
  </Grid>
</Grid>
 </Box>
 </Container>
</Box>
<br/>
</Box>





      
      </div>
    )
  }
}

export default Career