import React, { Component } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Divider,Button } from '@mui/material';
import logo from "../src/img/logo.png"
import PersonIcon from '@mui/icons-material/Person';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './Footer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Subheadertags from './Subheadertags';
import { keyframes } from '@mui/system';
const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;



export class Appheader extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      anchorEl : null,
      open: false
    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(event) {
    this.setAnchorEl(event.currentTarget);
}
    setAnchorEl(value){
        this.setState({
            anchorEl: value,
            open: !this.state.open
        })
    }
handleClose() {
    this.setAnchorEl(null);
}





renderMenu(){
  return(
    <Box sx={{}}>
  <Menu id="fade-menu" anchorEl={this.state.anchorEl} open={this.state.open}  onClose={this.handleClose}>
        <MenuItem component='a' href='/login' onClick={this.handleClose} >Login</MenuItem>
        <MenuItem component='a' href='/register' onClick={this.handleClose} >Register</MenuItem>
    </Menu>
    </Box>
   )
}






  render() {
    return (
      <div>
            
      <Box sx={{minHeight:90}}>
      <AppBar position="fixed" elevation={0} sx={{backgroundColor:'#fff',minHeight:90}}>
       <Container  maxWidth={false}>
      <Toolbar variant="dense">
     
       <Box sx={{flex:1,marginLeft:{xs:-3,sm:0,md:0,lg:0}}}>
       <img alt='logo' src={logo} style={{height:55,objectFit:'contain'}}/>
       </Box> 

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'right'}}>

<Box sx={{display:'flex',alignItems:'center'}}>
<Box sx={{display:'flex',justifyContent:'center'}}>

<Box onClick={()=>window.open('http://client.inccweb.in/')} sx={{display:{xs:'flex',sm:'flex',md:"flex",lg:'flex'},minWidth:70,justifyContent:'center',alignItems:'center',animation: `${blink} 1s linear infinite`,backgroundColor:'#0d6efd',borderRadius:5,mr:3}}>
<Typography sx={{color:'#fff',
fontWeight:'500',
ml:1,
mr:1,
padding:0.5,
fontSize:12,
textAlign:'center',
}}>START EXAM</Typography>
</Box>

</Box>

<IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 3 }}>
<PersonIcon sx={{color:'black',height:'60',width:'50'}}/>
</IconButton>

<IconButton edge="start" color="inherit" aria-label="menu" sx={{marginRight:{xs:-3,sm:0,md:0,lg:0}  }} aria-owns={this.state.open ? 'fade-menu' : undefined} aria-haspopup="true" onClick={this.handleClick}>
<MenuIcon sx={{color:'black',height:'60',width:'50'}}/> 
</IconButton>
{this.renderMenu()}
</Box>
</Box>
      </Toolbar> 
      </Container>

      <Box sx={{backgroundColor:'#282a36'}}>
      <Subheadertags/>
       </Box>

      </AppBar>
     </Box>
      </div>
    )
  }
}


export default Appheader