import React, { Component } from "react";
import { Box } from "@mui/system";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Link,
  CardMedia,
  Container,
  Card,
  TextField,
  Divider,
} from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../src/img/logo.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import base_url from "./base_url";

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  addEnquiry = () => {
    if (this.state.value !== "") {
      fetch(`${base_url.base_url}/addEnquiry`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          mob_no: this.state.value,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          this.setState(
            {
              value: "",
            },
            () => {
              alert(
                "Your request send to our team, we shortly contact to you!"
              );
            }
          );
        });
    } else {
      alert("plz enter mobile no");
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div>
        <br />
        <Box sx={{ backgroundColor: "white" }}>
          <Container maxWidth="xl">
            <Box sx={{ minHeight: 100, backgroundColor: "white" }}>
              <Box sx={{ m: 3 }}>
                <Grid container spacing={2}>
               
                <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box sx={{backgroundColor:'#fff',minHeight:100}}>
        
        <Box sx={{display:'flex',marginLeft:{xs:-3,sm:0,md:0,lg:0},justifyContent:{xs:'center',sm:'left'}}}>
        <img alt='logo' src={logo} style={{width:'80%'}}/>
        </Box> 

        <Typography sx={{mt:1,fontSize:14,textAlign:{xs:'center',sm:'left'}}}>
        Award Winning Student Experience
        </Typography>

        <Box sx={{height:2,width:{xs:'100%',sm:'100',md:'60%'},backgroundColor:'#c7167a',mt:2}}>
        </Box>
        </Box>
      </Grid>

      

      <Grid item xs={12} sm={12} md={9} lg={9}>
      <Box sx={{backgroundColor:'#fff',minHeight:100}}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{backgroundColor:'#fff',minHeight:100}}>
        
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        
      
        <Box sx={{display:'flex',flexDirection:'row',mt:1}}>
<CallIcon sx={{height:25,width:25,color:'black'}}/>
<Box>
<Typography sx={{marginLeft:2,color:'black',fontSize:18,fontWeight:'570'}} >0913-954-9561</Typography>
<Typography sx={{marginLeft:2,color:'black',fontSize:10,fontWeight:'570'}}>TIMING:9:00 AM TO 6:00 PM</Typography>
</Box>
</Box>

  <Box sx={{display:'flex',flexDirection:'row',mt:3}}>
        <EmailIcon sx={{height:25,width:25,color:'black'}}/>
        <Typography sx={{marginLeft:2,color:'black',fontSize:{xs:16,sm:16,md:18},fontWeight:'570'}} unwrap>7292961260no@gmail.com</Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        

        <Box sx={{display:'flex',flexDirection:'row',mt:1}}>
        <CallIcon sx={{height:25,width:25,color:'black'}}/>
        <Typography sx={{marginLeft:2,color:'black',fontSize:18,fontWeight:'570'}}>0913-954-9561</Typography>
        </Box>
        

        </Grid>
      </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <Box sx={{backgroundColor:'#fff',minHeight:100}}>

      <Box sx={{height:2,width:{xs:'100%',sm:'100',md:'60%'},mb:2,backgroundColor:'#c7167a',display:{xs:'block',sm:'none'}}}>
      </Box>

      <Typography  sx={{fontSize:16,fontWeight:'570',ml:1}}>Receive A Call Back From Our Team! </Typography>
      
      <Card sx={{display:'flex',flexDirection:'row',mt:1,height:55,borderRadius:10,mr:{xs:'0%',sm:'0%',md:'0%',lg:'0%'},borderBottom:2,borderBottomColor:'#f0f0f0',borderTop:1,borderTopColor:'#7b7b7b',justifyContent:'space-between'}}>

<Box sx={{display:'flex',alignItems:'center',ml:2}}>

<Box>
<TextField
         sx={{width:'100%',outline:0,ml:2}}
          name = "value"
          onChange={this.handleChange}
          placeholder="Enter number"
          id="outlined-error"
          value={this.state.value}
          variant='standard'
          InputProps={{
            disableUnderline:true,
            
          }}
        />
</Box>
</Box>
<Box sx={{display:'flex',alignItems:'center'}}>

<Box sx={{height:42,minWidth:140,backgroundColor:'#c7167a',display:'flex',justifyContent:'center',borderRadius:7,mr:1}}>
<Box sx={{display:'flex',alignItems:'center'}} onClick={this.send}>
<Typography color='white' sx={{fontSize:16,fontWeight:'550'}}>Get A Call Back</Typography>
</Box>
</Box>

</Box>
</Card>
      </Box>
      </Grid>
    </Grid>
      </Box>
      </Grid>
    </Grid>
      
                </Grid>

                <Divider />
                <br />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <WhatsAppIcon
                          onClick={() =>
                            window.open("https://wa.me/9033882974")
                          }
                          sx={{ height: 25, width: 25, color: "black" }}
                        />
                        <YouTubeIcon
                          sx={{ height: 25, width: 25, color: "black" }}
                        />
                        <InstagramIcon
                          sx={{ height: 25, width: 25, color: "black" }}
                        />
                        <FacebookIcon
                          sx={{ height: 25, width: 25, color: "black" }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="h1"
                              sx={{
                                fontSize: 15,
                                color: "#c7167a",
                                textAlign: "left",
                                fontWeight: "800",
                                fontFamily: "sans-serif",
                              }}
                            >
                              LOCATION
                            </Typography>
                            <Box
                              sx={{
                                height: 2,
                                width: { xs: "100%", sm: "100", md: "60%" },
                                mt: 2,
                                backgroundColor: "#c7167a",
                                display: { xs: "none", sm: "block" },
                              }}
                            ></Box>
                            <br />
                            <Link
                              href="/"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              pune
                            </Link>
                            <Link
                              href="/"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              nitro Park, pune
                            </Link>
                            <Link
                              href="/"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              4110162
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="h1"
                              sx={{
                                fontSize: 15,
                                color: "#c7167a",
                                textAlign: "left",
                                fontWeight: "800",
                                fontFamily: "sans-serif",
                              }}
                            >
                              WORK WITH US
                            </Typography>
                            <Box
                              sx={{
                                height: 2,
                                width: { xs: "100%", sm: "100", md: "60%" },
                                mt: 2,
                                backgroundColor: "#c7167a",
                                display: { xs: "none", sm: "block" },
                              }}
                            ></Box>
                            <br />

                            <Link
                              component="a"
                              href="/career"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Careers
                            </Link>
                            <Link
                              component="a"
                              href="/contactus"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Contact us
                            </Link>
                            <Link
                              component="a"
                              href="/faq"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              FAQ
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="h1"
                              sx={{
                                fontSize: 15,
                                color: "#c7167a",
                                textAlign: "left",
                                fontWeight: "800",
                                fontFamily: "sans-serif",
                              }}
                            >
                              ABOUT US
                            </Typography>
                            <Box
                              sx={{
                                height: 2,
                                width: { xs: "100%", sm: "100", md: "60%" },
                                mt: 2,
                                backgroundColor: "#c7167a",
                                display: { xs: "none", sm: "block" },
                              }}
                            ></Box>
                            <br />

                            <Link
                              href="/about"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              About Us
                            </Link>
                            <Link
                              href="/community"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Community
                            </Link>

                            <Link
                              href="/scholarship"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Scholarships
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="h1"
                              sx={{
                                fontSize: 15,
                                color: "#c7167a",
                                textAlign: "left",
                                fontWeight: "800",
                                fontFamily: "sans-serif",
                              }}
                            >
                              OTHER LINKS
                            </Typography>
                            <Box
                              sx={{
                                height: 2,
                                width: { xs: "100%", sm: "100", md: "60%" },
                                mt: 2,
                                backgroundColor: "#c7167a",
                                display: { xs: "none", sm: "block" },
                              }}
                            ></Box>
                            <br />

                            <Link
                              href="/refund_policy"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Refund policy
                            </Link>

                            <Link
                              href="/covid"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              covid-19
                            </Link>
                            <Link
                              href="/terms"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Terms and Conditions
                            </Link>
                            <Link
                              href="/privacy_policy"
                              sx={{
                                fontSize: 14,
                                fontWeight: "550",
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              Privacy Policy
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <br />
              </Box>

              <Box
                sx={{
                  maxHeight: 150,
                  backgroundColor: "#022c70",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    mt: 1,
                    mb: 1,
                    color: "white",
                    alignItems: "center",
                    fontFamily: "serif",
                  }}
                >
                  © 2024 INCC, ALL RIGHTS RESERVED.{" "}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </div>
    );
  }
}

export default Footer;
