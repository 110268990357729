import React, { Component } from 'react'
import Footer from '../src/Footer'
import Landingpage from './Landingpage'
import Appheader from './Appheader'
import { Container, Divider,Box } from '@mui/material'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Gridpage, { Gridpagec } from './Gridpage'
import Media from '../src/Media'
import Career from '../src/Career'
import Faqs from '../src/Faqs'
import Investor from './Investor'
import Community from './Community'
import Scholarship, { Schlorship } from './Schlorship'
import Privacy from './Privacy'
import Termcondition from './Termcondition'
import Covid from './Covid'
import Contactus from './Contactus'
import Aboutus from './About'
import Refund_policy from './Refund_policy'
import Login from './Login'
import Register from './Register'



  const router = createBrowserRouter([
    {
      path: "/",
      element:<Landingpage/>,
    },
   
    {
      path: "/main",
      element:<Gridpagec/>,
    },
   
    {
      path: "/about",
      element:<Aboutus/>,
    },
    {
      path: "/media",
      element:<Media/>,
    },
    {
      path: "/career",
      element:<Career/>,
    },
    {
      path: "/faq",
      element:<Faqs/>,
    },
    {
      path: "/investors",
      element:<Investor/>,
    },
    {
      path: "/community",
      element:<Community/>,
    },
    {
      path: "/scholarship",
      element:<Schlorship/>,
    },


    {
      path: "/privacy_policy",
      element:<Privacy/>,
    },
    {
      path: "/terms",
      element:<Termcondition/>,
    },
    {
      path: "/covid",
      element:<Covid/>,
    },
   
    {
      path: "/contactus",
      element:<Contactus/>,
    },

   // refund_policy

   {
    path: "/refund_policy",
    element:<Refund_policy/>,
  },
  {
    path: "/login",
    element:<Login/>,
  },
  {
    path: "/register",
    element:<Register/>,
  },



  ]);


export class Main extends Component {


  componentDidMount(){



  }




  render() {
    return (
      <div>
        <Box>



   <React.StrictMode>
     <RouterProvider router={router} />
   </React.StrictMode>



<Divider/>
    <Footer/>
    
    </Box>
      </div>
    )
  }
}

export default Main